export const environment = {
    production: true,
    envName: 'production-serv3',
    appId: 'br.com.pedidoagora.store',
    apiUrl: 'https://api3.pedidoagora.com.br',
    googleMapsApiKey: 'AIzaSyC-RNeDiv9-CfMnmmQRummyrM-OMF_LLSY',
    googleMapsUrl: 'https://maps.googleapis.com/maps/api/geocode/json',
    googleMapsApiUrl: 'https://maps.googleapis.com/maps/api',
    viaCepUrl: 'https://viacep.com.br',
    externalDomains: [
        'viacep.com.br/ws',
        'maps.googleapis.com/maps/api/geocode/json'
    ],
    version: '2.8.10'
};
